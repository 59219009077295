/**
 * KeyPadNumbersSlice.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import { createSlice } from '@reduxjs/toolkit';
import colors from '../strings/colors';
import { KEYS_10K, KEYS_15K, KEYS_50K } from '../../config/constants'; // Adjust import as necessary

const createKeyPadNumbersState = (keysConfig) => {
    return Array.from({ length: 13 }, (_, index) => ({
        number: index,
        color: getColor(index),
        keys: [...keysConfig.keys] // Assign keys based on the provided configuration
    }));
};

const getColor = (index) => {
    if(index === 0) {
        return colors.green;
    }

    return colors[index % 2 === 0 ? 'black' : 'red'];
}
export const keyPadNumbersSlice = createSlice({
    name: 'keyPadNumbers',
    initialState: {
        keyPadNumbers: createKeyPadNumbersState(KEYS_50K), // Set initial state with KEYS_10K or any other config
    },

    reducers: {
        // Reset all keys to off
        resetAllKeys: (state) => {
            state.keyPadNumbers.forEach((keyPadNumber) => {
                keyPadNumber.keys.forEach(key => key.isOn = false);
            });
        },

        // Toggle key state
        toggleKey: (state, action) => {
            const { number, key } = action.payload;
            const targetKey = state.keyPadNumbers[number].keys.find(k => k.number === key);
            if (targetKey) {
                targetKey.isOn = !targetKey.isOn;
            }
        },

        // Set keys configuration dynamically
        setKeysConfiguration: (state, action) => {
            state.keyPadNumbers = createKeyPadNumbersState(action.payload);
        }
    },
});

export const keyPadNumbersActions = keyPadNumbersSlice.actions;
