/**
 * RoundedButton.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import React from 'react';
import { TouchableOpacity, StyleSheet, Text } from 'react-native';
import { EvilIcons } from '@expo/vector-icons'; 
import colors from '../../strings/colors';

const IconButton = ({ title, iconName, onPress }) => (
  <TouchableOpacity style={styles.container} onPress={onPress}>
    <Text style={styles.text} >{title}</Text>
    <EvilIcons name={iconName} size={28} color={colors.white} />
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray ,
    borderRadius: 25,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 15,
    height: 50,
  },
  text: {
    fontFamily: 'Poppins-Bold',
    color: colors.yellow,
    fontWeight: 'bold',
    marginRight: 10,
    fontSize: 17,
    fontWeight: 'bold',
  },
});

export default IconButton;
