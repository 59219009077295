/**
 * internet-strengh.const.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const NONE = 'none';
const UNKNOWN = 'unknown';
const WEAK = 'weak';
const GOOD = 'good';

export {
    NONE,
    UNKNOWN,
    WEAK,
    GOOD
}

