/**
 * game.sounds.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import useSound from 'use-sound';

import {
    BEEP,
    SPIN,
    WIN_100_OR_HIGHER,
    WIN_50_OR_HIGHER,
    WIN_10_OR_HIGHER
} from '../../../../../config/constants';

// Note: These functions need to be called within a React component or hook because useSound is a hook.
const useBeep = () => {
    const [play] = useSound(BEEP);
    return play;
};

const useSpinSound = () => {
    const [play] = useSound(SPIN);
    return play;
};

const useWin100OrHigher = () => {
    const [play] = useSound(WIN_100_OR_HIGHER);
    return play;
};

const useWin50OrHigher = () => {
    const [play] = useSound(WIN_50_OR_HIGHER);
    return play;
};

const useWin10OrHigher = () => {
    const [play] = useSound(WIN_10_OR_HIGHER);
    return play;
};

export {
    useBeep,
    useSpinSound,
    useWin100OrHigher,
    useWin50OrHigher,
    useWin10OrHigher
};
