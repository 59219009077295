import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Platform, Text, Modal, TouchableOpacity } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import { MaterialIcons } from '@expo/vector-icons';
import colors from '../../strings/colors';
import { GOOD, NONE, UNKNOWN, WEAK } from '../../../config/constants/internet-strengh.const';

const InternetStatusIcon = () => {
  const [isConnected, setIsConnected] = useState(true);
  const [internetStrength, setInternetStrength] = useState(GOOD);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      if (state.type === NONE || state.type === UNKNOWN) {
        setInternetStrength(NONE);
      } else if (state.strength && state.strength <= 2) {
        setInternetStrength(WEAK);
      } else {
        setInternetStrength(GOOD);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  let iconColor = isConnected ? colors.light_green : colors.red;
  if (internetStrength === WEAK) {
    iconColor = colors.orange;
  } else if (internetStrength === NONE) {
    iconColor = colors.red;
  }

  const showModal = () => {
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={showModal}>
        <MaterialIcons
          name={isConnected ? 'link' : 'link-variant-off'}
          size={28}
          color={iconColor}
        />
      </TouchableOpacity>
      <Modal animationType="fade" transparent={true} visible={modalVisible} onRequestClose={hideModal}>
        <TouchableOpacity style={styles.modalContainer} onPress={hideModal}>
          <View style={styles.modalView}>
            {internetStrength === NONE && (
              <Text style={[styles.modalText,{color:colors.red}]}>
                You are currently offline. Please connect to the internet to play the game.
              </Text>
            )}
            {internetStrength === WEAK && (
              <Text  style={[styles.modalText,{color:colors.orange}]}>
                Your internet connection is weak. Your gaming experience may be affected. Please consider switching to a stronger network.
              </Text>
            )}
            {internetStrength === GOOD && (
              <Text style={[styles.modalText,{color:colors.light_green}]}>
                Your internet connection is good. You should be able to enjoy a seamless gaming experience.
              </Text>
            )}
          </View>
        </TouchableOpacity>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginLeft: 25,
    marginTop: 3,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
  },
  modalText: {
    fontSize: 16,
    textAlign: 'center',
    color: colors.green
  },
});

export default InternetStatusIcon;
