/**
 * jackpot-max-wins.const.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import { KEYS_10K, KEYS_15K, KEYS_50K } from "./keypad-constants";

const MAX_10K = '10K';
const MAX_15K = '15K';
const MAX_50K = '50K';

const JACKPOT_MAX_WINS = [MAX_10K, MAX_15K, MAX_50K];

const MAX_WIN_KEYBOARD_MAPPING = {
    [MAX_10K]: KEYS_10K,
    [MAX_15K]: KEYS_15K,
    [MAX_50K]: KEYS_50K,
};

const MIN_BET_MAPPING = {
    [MAX_10K]: "1.00",
    [MAX_15K]: "1.00",
    [MAX_50K]: "5.00",
}

export { 
    JACKPOT_MAX_WINS, 
    MAX_10K, MAX_15K, 
    MAX_50K,
    MAX_WIN_KEYBOARD_MAPPING,
    MIN_BET_MAPPING
};