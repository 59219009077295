/**
 * Terms.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

import React, {useState} from 'react'
import { View, Text, StyleSheet, Linking } from 'react-native'
import colors from '../../strings/colors';
import Checkbox from 'expo-checkbox';
import { SISKUS_TERMS_AND_CONDITIONS } from '../../../config/constants/links.conts';



function Terms({ onTermsAccepted }) {
    
    const [termsAccepted, setTermsAccepted] = useState(false); // [1]

   const toggleTermsCheckbox = () => {
     setTermsAccepted(!termsAccepted);
     onTermsAccepted(!termsAccepted);
   };
    const openTermsLink = () => {
        Linking.openURL(SISKUS_TERMS_AND_CONDITIONS);
    };

  return (
    <View style={styles.checkboxContainer}>
       
        <Checkbox value={termsAccepted} onValueChange={toggleTermsCheckbox} />
        
         
        <Text style={styles.checkboxText}>
        I agree to the{' '}
        <Text style={styles.linkText} onPress={openTermsLink}>
            terms and conditions
        </Text>
        </Text>
    </View>
  )
}

const styles = StyleSheet.create({
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 15,
        marginLeft: 10,
    },
    checkboxText: {
        fontFamily: 'Poppins-Bold',
        color:colors.gray,
        marginLeft: 10,
    },
    linkText: {
        color: 'blue',
        textDecorationLine: 'underline',
    },
})

export default Terms

