/**
 * index.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

/**
 * EXPO_PUBLIC_API_ENDPOINT='https://siskusserver.com/api'
EXPO_PUBLIC_APP_ENCRYPTION_KEY='0xYg3n@2024'
EXPO_PUBLIC_APP_FRONTEND_URL='https://jackpot.siskus.com'
EXPO_PUBLIC_APP_MODE='SISKUS'
EXPO_PUBLIC_APP_ENV='production'
 */

const EXPO_PUBLIC_API_ENDPOINT = process.env.EXPO_PUBLIC_API_ENDPOINT || 'https://siskusserver.com/api';
//const EXPO_PUBLIC_API_ENDPOINT = process.env.EXPO_PUBLIC_API_ENDPOINT || 'http://localhost:8090/api';
//const EXPO_PUBLIC_BASE_URL = process.env.EXPO_PUBLIC_BASE_URL || 'http://localhost:8090';
const EXPO_PUBLIC_BASE_URL = process.env.EXPO_PUBLIC_BASE_URL || 'https://siskusserver.com';
const EXPO_PUBLIC_APP_ENCRYPTION_KEY = process.env.EXPO_PUBLIC_APP_ENCRYPTION_KEY || '0xYg3n@2024';
const EXPO_PUBLIC_APP_FRONTEND_URL = process.env.EXPO_PUBLIC_APP_FRONTEND_URL || 'https://jackpot.siskus.com';
const EXPO_PUBLIC_APP_MODE = process.env.EXPO_PUBLIC_APP_MODE || 'SISKUS';
const EXPO_PUBLIC_APP_ENV = process.env.EXPO_PUBLIC_APP_ENV || 'production';
const APP_VERSION = '2';

export {
    EXPO_PUBLIC_API_ENDPOINT,
    EXPO_PUBLIC_BASE_URL,
    EXPO_PUBLIC_APP_ENCRYPTION_KEY,
    EXPO_PUBLIC_APP_FRONTEND_URL,
    EXPO_PUBLIC_APP_MODE,
    EXPO_PUBLIC_APP_ENV,
    APP_VERSION
}