import { AppState } from 'react-native';
import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./userSlice";
import { api } from "./api";
import { setItem, getItem, removeItem } from './storage';
import { soundSlice } from './soundSlice';
import { colorSlice } from './colorSlice';
import { numbersSlice } from './numbersSlice';
import { multipliersSlice } from './multipliersSlice';
import { keyPadNumbersSlice } from './keyPadNumbersSlice';
import { gameSlice } from './gameSlice';
import { settingsSlice } from './settingsSlice';

const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        sound: soundSlice.reducer,
        color: colorSlice.reducer,
        numbers: numbersSlice.reducer,
        multipliers: multipliersSlice.reducer,
        keyPadNumbers : keyPadNumbersSlice.reducer,
        settings: settingsSlice.reducer,
        game: gameSlice.reducer,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
});

let appState = AppState.currentState;

const handleAppStateChange = async (nextAppState) => {
    if (appState.match(/inactive|background/) && nextAppState === "active") {
        // App has come to the foreground
    } else if (appState === "active" && nextAppState.match(/inactive|background/)) {
        // App is going to the background or becoming inactive
        const state = store.getState();
        await setItem('reduxState', JSON.stringify(state));
    }

    appState = nextAppState;
};

AppState.addEventListener('change', handleAppStateChange);

// Asynchronously load the state and update the store
const loadInitialState = async () => {
    const initialState = await getItem('reduxState');
    if (initialState) {
        store.dispatch({
            type: 'LOAD_INITIAL_STATE',
            payload: JSON.parse(initialState) 
        });
    }
    // Don't remove the saved state if you want it to be persistent
    // await removeItem('reduxState');
};

loadInitialState();

export default store;
