/**
 * links.conts.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const  SISKUS_TERMS_AND_CONDITIONS = 'https://siskus.com/document/Terms%20and%20Conditions%20.pdf';
const  SISKUS_WEBSITE = 'https://siskus.com';

export {
    SISKUS_TERMS_AND_CONDITIONS,
    SISKUS_WEBSITE
}