/**
 * sound-constants.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */

const BEEP = require("../../assets/sounds/beep.mp3");
const SPIN = require("../../assets/sounds/spin.mp3");
const WIN_100_OR_HIGHER = require("../../assets/sounds/williwin.mp3");
const WIN_50_OR_HIGHER = require("../../assets/sounds/winehe.mp3");
const WIN_10_OR_HIGHER = require("../../assets/sounds/win.mp3");

export { BEEP, SPIN, WIN_100_OR_HIGHER, WIN_50_OR_HIGHER, WIN_10_OR_HIGHER };
