import React from 'react'
import { TouchableOpacity, StyleSheet, Text, View } from 'react-native'
import colors from '../../strings/colors'
import NumberKey from './NumberKey'

function KeypadNumber({number,backgroundColor, onPress}) {

  return (
    <View style={[styles.container,{backgroundColor}]}>
     <View style={styles.first_row}>
        <View style={styles.left_key}>
            <NumberKey number={number.keys[0].number} isOn={number.keys[0].isOn} onPress={onPress} keyPadNumber={number.number}/> 
        </View>
        <View style={styles.empty_view} />
        <View style={styles.right_key}>
            <NumberKey number={number.keys[1].number} isOn={number.keys[1].isOn} onPress={onPress} keyPadNumber={number.number}/>
        </View>
     </View>
     <View style={styles.first_row}>
        <Text style={styles.text}>{number.number}</Text>
     </View>
     <View style={styles.first_row}>
        <View style={styles.left_key}>
            <NumberKey number={number.keys[2].number} isOn={number.keys[2].isOn} onPress={onPress} keyPadNumber={number.number}/> 
        </View>
        <View style={styles.empty_view} />
        <View style={styles.right_key}>
            <NumberKey number={number.keys[3].number} isOn={number.keys[3].isOn} onPress={onPress} keyPadNumber={number.number}/>
        </View>
     </View>
   </View>

  )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#e6e6e6',
        borderRadius: 8,
        width: 70,
        height: 70,
        padding:2,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    first_row: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    empty_view: {
        flex: 1,
        width: 30,
        height: 30,
    },
    left_key: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    right_key: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    text: {
        fontFamily: 'Gloucester',
        color: colors.white,
        fontSize: 28,
        fontWeight: 'bold',
    },
      
})

export default React.memo(KeypadNumber)