/**
 * settingsSlice.js
 *
 * @author ...
 * ...
 */

import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Switch, TouchableOpacity } from 'react-native';
import { RadioButton } from 'react-native-paper'; // Import RadioButton from react-native-paper
import RoundedButton from '../../components/common/RoundedButton';
import colors from '../../strings/colors';
import { useSelector, useDispatch } from 'react-redux';
import { settingsActions } from '../../redux/settingsSlice';
import { useUpdateSettingsMutation } from '../../redux/api';
import Loader from '../../components/common/Loader';
import SiskusIcon from '../../components/common/SiskusIcon';
import Toast from 'react-native-toast-message';
import { JACKPOT_MAX_WINS, MAX_WIN_KEYBOARD_MAPPING } from '../../../config/constants/jackpot-max-wins.const';
import { COLOR_MAP } from '../../../config/constants';
import { keyPadNumbersActions } from '../../redux/keyPadNumbersSlice';

const Settings = () => {
  const dispatch = useDispatch();

  // Selectors to get current settings from Redux store
  const background = useSelector((state) => state.settings.background);
  const soundOn = useSelector((state) => state.settings.sound_on);
  const jackpotMaxWin = useSelector((state) => state.settings.jackpot_max_win);
  const userId = useSelector((state) => state.user._id);

  // Local state to manage form inputs
  const [selectedBackground, setSelectedBackground] = useState(background);
  const [sound, setSound] = useState(soundOn);
  const [selectedJackpotMaxWin, setSelectedJackpotMaxWin] = useState(jackpotMaxWin);
  const [status, setStatus] = useState('');

  const settings = useSelector((state) => state.settings);

  const [updateSettings, updateSettingsResult] = useUpdateSettingsMutation();

  // Handler for updating settings
  const handleUpdate = () => {
    // Optionally add validation logic here

    updateSettings({
      userId,
      background: settings.background,
      sound_on: settings.sound_on,
      jackpot_max_win: settings.jackpot_max_win,
    });
  };

  // Handlers for individual settings
  const handleBackgroundChange = (color) => {
    setSelectedBackground(color);
    dispatch(settingsActions.setBackground(color));
  };

  const handleSoundToggle = () => {
    setSound((previousState) => !previousState);
    dispatch(settingsActions.toggleSound());
  };

  const handleJackpotMaxWinChange = (value) => {
    setSelectedJackpotMaxWin(value);
    dispatch(settingsActions.setJackpotMaxWin(value));
    dispatch(keyPadNumbersActions.setKeysConfiguration(MAX_WIN_KEYBOARD_MAPPING[value]));
  };

  // Effect to handle update results
  useEffect(() => {
    if (updateSettingsResult.isSuccess) {
      Toast.show({
        type: 'success',
        text1: 'Success',
        text2: 'Settings updated successfully',
      });
      // Optionally, update other parts of the state if needed
    }
    if (updateSettingsResult.isError) {
      Toast.show({
        type: 'error',
        text1: 'Error',
        text2: updateSettingsResult.error.message,
      });
      console.log(updateSettingsResult.error);
    }
  }, [updateSettingsResult.isSuccess, updateSettingsResult.isError]);

  // Show loader while updating settings
  if (updateSettingsResult.isLoading) {
    return <Loader />;
  }

  return (
    <View style={styles.container}>
      <View style={styles.layout}>
        <SiskusIcon title={'Settings'} description={'Update Your Settings'} />

        {/* Background Color Selection */}
        <Text style={styles.label}>Jackpot Background Color</Text>
        <View style={styles.colorContainer}>
          {['blue', 'pink', 'green'].map((colorOption) => (
            <TouchableOpacity
              key={colorOption}
              style={[
                styles.colorBox,
                { backgroundColor: COLOR_MAP[colorOption].dark },
                settings.background === colorOption && styles.selectedColorBox,
              ]}
              onPress={() => handleBackgroundChange(colorOption)}
            />
          ))}
        </View>

        {/* Jackpot Max Win Radio Buttons */}
        <Text style={styles.label}>Jackpot Max Win</Text>
        <RadioButton.Group
          onValueChange={handleJackpotMaxWinChange}
          value={selectedJackpotMaxWin}
        >
          {JACKPOT_MAX_WINS.map((maxWin) => (
            <View key={maxWin} style={styles.radioButtonContainer}>
              <RadioButton value={maxWin} />
              <Text style={styles.radioLabel}>N$ {maxWin}</Text>
            </View>
          ))}
        </RadioButton.Group>

        {/* Sound Toggle Switch */}
        <View style={styles.soundContainer}>
          <Text style={styles.label}>Sound</Text>
          <Switch
            value={sound}
            onValueChange={handleSoundToggle}
            trackColor={{ false: colors.gray, true: colors.blue }}
            thumbColor={sound ? colors.blue : colors.gray}
          />
        </View>

        {/* Status Text */}
        <Text style={styles.statusText}>{status}</Text>

        {/* Update Button */}
        <RoundedButton title="UPDATE SETTINGS" onPress={handleUpdate} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  layout: {
    width: '90%',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
  },
  statusText: {
    color: colors.orange,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
  colorContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  colorBox: {
    width: 50,
    height: 50,
    borderRadius: 25,
    borderWidth: 2,
    borderColor: 'transparent',
  },
  selectedColorBox: {
    borderColor: colors.orange, // Highlight color for selected box
    borderWidth: 2,
  },
  radioButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  radioLabel: {
    fontSize: 16,
    fontFamily: 'Poppins-Bold',
    color: colors.light_gray,
  },
  soundContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 20,
  },
  label: {
    fontFamily: 'Poppins-Bold',
    fontSize: 16,
    marginLeft: 10,
    marginBottom: 8,
  },
});

export default Settings;
