import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import colors from '../../strings/colors';
import MultiplierNumber from './MultiplierNumber';
import { useSelector } from 'react-redux';
import { MIN_BET_MAPPING } from '../../../config/constants/jackpot-max-wins.const';
import { COLOR_MAP } from '../../../config/constants';

function WinningMultiplier() {
  const multipliers = useSelector(state => state.multipliers.multipliers);
  const settings = useSelector(state => state.settings);

  return (
    <View style={[{
      backgroundColor: COLOR_MAP[settings.background]['dark']
    },styles.parentContainer]}>
      {/* Labels Container */}
      <View style={styles.labelsContainer}>
        <Text style={styles.labelText}>MAX WIN N${settings.jackpot_max_win}</Text>
        <Text style={styles.labelText}>MIN BET N${MIN_BET_MAPPING[settings.jackpot_max_win]}</Text>
      </View>

      {/* Multipliers Wrapper */}
      <View style={styles.container}>
        {multipliers.map((number, index) => (
          <MultiplierNumber 
            key={index} 
            number={number.number} 
            isOn={number.isOn} 
            width={number.width}
          />
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  parentContainer: {
    width: '100%',
    padding: 10,
    borderRadius: 12,
    // Optional: Add shadow for better appearance
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3, // For Android shadow
    marginVertical: 10, // Space above and below the component
  },
  labelsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8, // Space between labels and multipliers
    paddingHorizontal: 5,
  },
  labelText: {
    fontSize: 9,
    fontWeight: 'bold',
    color: '#fff',
    fontFamily: 'Gloucester',
    textShadowColor: '#fff',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 8,
  },
  container: {
    backgroundColor: colors.yellow,
    height: 35, // Increased height for better visibility
    width: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: 10,
    padding: 2, // Increased padding for better spacing
    // Optional: Add subtle shadow inside the container
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 2, // For Android shadow
  },
});

export default React.memo(WinningMultiplier);
