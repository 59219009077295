/**
 * settingsSlice.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import { createSlice } from '@reduxjs/toolkit';
import { MAX_10K } from '../../config/constants/jackpot-max-wins.const';

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        background : 'blue',
        jackpot_max_win: MAX_10K,
        sound_on: true
    },
    reducers: {

        setSetting: (state,action)=>{
            state.background = action.payload.background;
            state.jackpot_max_win = action.payload.jackpot_max_win;
            state.sound_on = action.payload.sound_on;
        },
    
        setBackground: (state, action) => {
            state.background = action.payload;
        },
        setJackpotMaxWin: (state, action) => {
            state.jackpot_max_win = action.payload;
        },
        toggleSound: (state) => {
            state.sound_on = !state.sound_on;
        },

        resetSettings: (state) => {
            background = 'blue',
            jackpot_max_win = MAX_10K,
            sound_on = true
        }
    },
});

export const settingsActions = settingsSlice.actions;