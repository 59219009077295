import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import colors from '../../strings/colors';

function MultiplierNumber({ number, isOn, width = 50 }) { // Set a default width if not provided
  return (
    <View style={[styles.container, { width }]}>
      <Text style={[styles.text, isOn && styles.on]}>
        {number}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.red,
    borderRadius: 10,
    justifyContent: 'center', // Centers vertically
    alignItems: 'center',     // Centers horizontally
    margin: 2,
    height: 30,               // Define a fixed height for consistent centering
  },

  on: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#fff',
    textShadowColor: '#fff',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 8,
  },

  text: {
    color: colors.light_gray,
    fontSize: 19,
    fontFamily: 'Gloucester',
    fontWeight: 'bold',
  },
});

export default React.memo(MultiplierNumber);
