import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Image,
  TouchableOpacity,
  Linking,
  Clipboard,
} from 'react-native';
import SiskusIcon from '../../components/common/SiskusIcon'; 
import colors from '../../strings/colors'; 
import Toast from 'react-native-toast-message';
import { useSelector } from 'react-redux';
import WhatsApp from '../../components/common/WhatsApp';
import { useNavigation } from '@react-navigation/core'; // Import useNavigation

const Payin = () => {
  const user_state = useSelector(state => state.user);
  const navigation = useNavigation(); // Initialize navigation

  const [customerPhoneNumber, setCustomerNumber] = React.useState(user_state.cellphone);

  const paymentOptions = [
    {
      image: require('../../../assets/images/cards.png'),
      title: 'CARD PAYMENT',
      description: 'Very Secure, Easy & Fast',
      link: 'http://credit.siskus.com', // This link will be ignored for CARD PAYMENT
      phoneNumber: '', // No phone number for card payment
      message: `Hi, I would like to top up my account ${customerPhoneNumber}.`,
      navigateTo: 'BuyCredit', // Add a navigateTo property
    },
    {
      image: require('../../../assets/images/atm.png'),
      title: 'FNB ATM CASH DEPOSIT',
      description: 'SISKUS GAMMING | Acc NO: 62276784785 | FNB Windhoek Branch',
      link: 'https://www.google.com/search?rlz=1C1BNSD_enNA929NA929&tbs=lf:1,lf_ui:4&tbm=lcl&sxsrf=ALeKk03rsg1lcH2js1IM1FZw2A35WxvGhA:1624542043957&q=FNB+Deposit+atms&rflfq=1&num=10&sa=X&ved=2ahUKEwjGmM_bsrDxAhVCJMUKHT4UDgoQjGp6BAgNEDw&biw=1536&bih=664#rlfi=hd:;si:;mv:[[-22.5150531,17.1068857],[-22.6261798,17.043526699999997]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u16!2m2!16m1!1e1!1m4!1u16!2m2!16m1!1e2!2m1!1e16!2m1!1e3!3sIAE,lf:1,lf_ui:4',
      phoneNumber: '+264818210855',
      message: `Hi there, I am sending money via FNB ATM CASH DEPOSIT to top up my account ${customerPhoneNumber}.`,
    },
    {
      image: require('../../../assets/images/fnb.png'),
      title: 'FNB PAY 2 CELL',
      description: 'Pay 2 Cell To 0814494796',
      link: 'tel:*140*321#',
      phoneNumber: '+264814494796',
      message: `Hi there, I am sending money via FNB PAY 2 CELL to top up my account ${customerPhoneNumber}.`,
    },
    {
      image: require('../../../assets/images/ewallet.png'),
      title: 'E-WALLET',
      description: 'Send To 0818210855',
      link: 'tel:*140*321#',
      phoneNumber: '+264818210855',
      message: `Hi there, I am sending money via E-WALLET to top up my account ${customerPhoneNumber}.`,
    },
    {
      image: require('../../../assets/images/whk.png'),
      title: 'EASY WALLET',
      description: 'Send To 0818210855',
      link: 'tel:*140*295#',
      phoneNumber: '+264818210855',
      message: `Hi there, I am sending money via EASY WALLET to top up my account ${customerPhoneNumber}.`,
    },
    {
      image: require('../../../assets/images/sbank.png'),
      title: 'BLUE WALLET',
      description: 'Send To 0818210855',
      link: 'tel:*140*2345#',
      phoneNumber: '+264818210855',
      message: `Hi there, I am sending money via BLUE WALLET to top up my account ${customerPhoneNumber}.`,
    },
  ];

  const handlePress = (option) => {
    if (option.navigateTo) {
      // If navigateTo property exists, navigate to the specified screen
      navigation.navigate(option.navigateTo);
    } else {
      // Otherwise, open the link
      Linking.openURL(option.link).catch(err =>
        Toast.show({
          type: 'error',
          text1: 'Error',
          text2: 'Failed to open the link.',
          position: 'bottom',
        })
      );
    }
  };

  const copyToClipboard = (text) => {
    Clipboard.setString(text);
    Toast.show({
      type: 'success',
      text1: 'Success',
      text2: `Copied ${text} to clipboard`,
      position: 'bottom',
    });
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <SiskusIcon description="Choose Your Payment Option" />
      {paymentOptions.map((option, index) => (
        <View key={index} style={styles.option}>
          <TouchableOpacity
            style={styles.optionTouchable}
            onPress={() => handlePress(option)}
          >
            <Image source={option.image} style={styles.optionImage} />
            <View style={styles.optionText}>
              <Text style={styles.optionTitle}>{option.title}</Text>
              <Text style={styles.optionDescription}>{option.description}</Text>
            </View>
          </TouchableOpacity>
          <View style={styles.actions}>
            {option.phoneNumber ? (
              <>
                <TouchableOpacity onPress={() => copyToClipboard(option.phoneNumber)}>
                  <Text style={styles.copyText}>Copy #</Text>
                </TouchableOpacity>
                <WhatsApp number={option.phoneNumber} message={option.message} />
              </>
            ) : null}
          </View>
        </View>
      ))}
      <Toast />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    padding: 16,
  },
  option: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.gray,
    borderRadius: 25,
    marginBottom: 16,
    padding: 16,
  },
  optionTouchable: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  optionImage: {
    width: 70,
    height: 70,
    borderRadius: 35,
    marginRight: 20,
  },
  optionText: {
    fontFamily: 'Poppins-Bold',
    color: colors.white,
    flex: 1,
  },
  optionTitle: {
    fontSize: 18,
    fontFamily: 'Poppins-Bold',
    color: colors.yellow,
  },
  optionDescription: {
    fontSize: 14,
    color: colors.light_gray,
    fontFamily: 'Poppins-Bold',
  },
  actions: {
    marginLeft: 10,
    alignItems: 'flex-end',
  },
  copyText: {
    fontFamily: 'Poppins-Bold',
    color: colors.blue,
    textDecorationLine: 'underline',
    marginBottom: 5,
  },
});

export default Payin;
