/**
 * BuyCredit.jsx
 *
 * @autor Immanuel N Fransiskus <immanuel@siskus.com>
 * All rights reserved
 */

import React, { useEffect, useState, useCallback } from 'react';
import { 
  View, 
  Text, 
  StyleSheet, 
  TouchableOpacity, 
  Platform,
  Modal, 
  TouchableWithoutFeedback 
} from 'react-native';
import RoundedButton from '../../components/common/RoundedButton';
import colors from '../../strings/colors';
import SiskusIcon from '../../components/common/SiskusIcon';
import RoundedInput from '../../components/common/RoundedInput';
import { useBuyCreditMutation } from '../../redux/api'; 
import { useNavigation } from '@react-navigation/core';
import Loader from '../../components/common/Loader';
import { useSelector } from 'react-redux';
import Toast from 'react-native-toast-message';

const BuyCredit = () => {
  const [grantTotal, setGrantTotal] = useState('100'); // Default value as string for TextInput
  const [selectedAmount, setSelectedAmount] = useState(100);
  const [paymentUrl, setPaymentUrl] = useState(null); // New state for payment URL
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false); // New state for modal visibility

  const [buyCredit, buyCreditResult] = useBuyCreditMutation();

  const navigation = useNavigation();

  const user_state = useSelector(state => state.user);

  // Define color mapping based on denomination with prefixed color names
  const denominationColors = {
    10: colors.noteLightBlue,   // Light Blue for 10 N$
    20: colors.noteRed,         // Red for 20 N$
    30: colors.noteLightBlue,       // Green for 30 N$
    50: colors.noteGreen,      // Orange for 50 N$
    100: colors.noteDarkRed,    // Dark Red for 100 N$
    200: colors.notePurple,     // Purple for 200 N$
  };

  const handleAmountPress = useCallback((amount) => {
    setGrantTotal(amount.toString());
    setSelectedAmount(amount);
  }, []);

  const handleBuy = async () => {
    const userId = user_state._id;
    if (!userId) {
      Toast.show({
        type: 'error',
        position: 'top',
        text1: 'Error',
        text2: 'User not found. Please log in again.',
      });
      navigation.navigate('Login');
      return;
    }

    const total = parseInt(grantTotal, 10);
    if (isNaN(total) || total <= 0) {
      Toast.show({
        type: 'error',
        position: 'top',
        text1: 'Invalid Amount',
        text2: 'Please enter a valid credit amount.',
      });
      return;
    }

    try {
      const response = await buyCredit({ userId, grantTotal: total }).unwrap();
      // Assuming the response contains the 'url' field
      if (response.url) {
        setPaymentUrl(response.url);
        setIsConfirmationVisible(true); // Show the confirmation modal
      } else {
        Toast.show({
          type: 'error',
          position: 'top',
          text1: 'Payment Error',
          text2: 'Invalid payment link received.',
        });
      }
    } catch (error) {
      Toast.show({
        type: 'error',
        position: 'top',
        text1: 'Payment Error',
        text2: error?.data?.message || 'Something went wrong.',
      });
    }
  };

  const handleMakePayment = () => {
    if (Platform.OS === 'web') {
      console.log('Opening URL:', paymentUrl);
      // For Web: Open the URL in a new browser tab
      window.open(paymentUrl, '_blank');
      navigation.navigate('Jackpot')
    } else {
      // For iOS and Android: Navigate to MyWebView
      navigation.navigate('MyWebView', { url: paymentUrl });
    }
  };

  if(buyCreditResult.isLoading) {
    return <Loader />;
  }

  return (
    <View style={styles.container}>
      <SiskusIcon title={'Buy Credit'} description={'BUY SISKUS ONLINE'} />
      <RoundedInput
        placeholder="Enter Credit Amount"
        keyboardType='numeric'
        value={grantTotal}
        onChangeText={setGrantTotal}
      />
      <View style={styles.coinContainer}>
        {[10, 20, 30, 50, 100, 200].map((amount) => (
          <TouchableOpacity
            key={amount}
            style={[
              styles.coinButton,
              { backgroundColor: denominationColors[amount] || colors.lightGray2 },
              selectedAmount === amount && styles.selectedCoinButton
            ]}
            onPress={() => handleAmountPress(amount)}
            activeOpacity={0.7}
            accessibilityLabel={`Select ${amount} credits`}
            accessibilityRole="button"
          >
            <Text style={[
              styles.coinText,
              selectedAmount === amount && styles.selectedCoinText
            ]}>{amount}</Text>
          </TouchableOpacity>
        ))}
      </View>
      {paymentUrl ? (
        <RoundedButton title="MAKE PAYMENT NOW" onPress={handleMakePayment} />
      ) : (
        <RoundedButton title="BUY CREDIT" onPress={handleBuy} />
      )}
      {buyCreditResult.error && (
        <Text style={styles.errorText}>
          {buyCreditResult.error.data?.message || 'An error occurred.'}
        </Text>
      )}
      <Toast />

      {/* Confirmation Modal */}
      <Modal
        transparent={true}
        animationType="fade"
        style= {{
            fontFamily: 'Poppins-Bold'
        }}
        visible={isConfirmationVisible}
        onRequestClose={() => setIsConfirmationVisible(false)} // Handle back button press on Android
      >
        <TouchableWithoutFeedback onPress={() => setIsConfirmationVisible(false)}>
          <View style={styles.modalOverlay} />
        </TouchableWithoutFeedback>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Confirm Payment</Text>
            <Text style={styles.modalMessage}>
              You are about to make a payment of {grantTotal} credits. Do you want to proceed?
            </Text>
            <View style={styles.modalButtons}>
              <TouchableOpacity
                style={[styles.modalButton, styles.cancelButton]}
                onPress={() => setIsConfirmationVisible(false)}
                accessibilityLabel="Cancel Payment"
                accessibilityRole="button"
              >
                <Text style={styles.cancelButtonText}>Cancel</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.modalButton, styles.proceedButton]}
                onPress={() => {
                  setIsConfirmationVisible(false);
                  handleMakePayment();
                }}
                accessibilityLabel="Proceed with Payment"
                accessibilityRole="button"
              >
                <Text style={styles.proceedButtonText}>Proceed</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: colors.white,
    justifyContent: 'center',
  },
  coinContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginVertical: 20,
  },
  coinButton: {
    width: 60,
    height: 60,
    borderRadius: 30, // Half of width and height to make it circular
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 15,
    // Flat design: no shadow or elevation
    shadowColor: 'transparent',
    elevation: 0,
  },
  selectedCoinButton: {
    borderWidth: 2,
    borderColor: colors.black, // Highlight border when selected
  },
  coinText: {
    fontSize: 22,
    color: colors.black,
    fontWeight: 'bold',
    fontFamily: 'Poppins-Bold',
  },
  selectedCoinText: {
    color: colors.white,
  },
  errorText: {
    color: colors.red,
    textAlign: 'center',
    marginTop: 10,
    fontSize: 14,
    fontFamily: 'Poppins-Regular',
  },
  // Modal Styles
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  },
  modalContainer: {
    position: 'absolute',
    top: '40%',
    left: '10%',
    right: '10%',
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 20,
    elevation: 5, // For Android shadow
    shadowColor: '#000', // For iOS shadow
    shadowOffset: { width: 0, height: 2 }, // For iOS shadow
    shadowOpacity: 0.25, // For iOS shadow
    shadowRadius: 3.84, // For iOS shadow
  },
  modalContent: {
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    fontFamily: 'Poppins-Bold',
    color: colors.black,
  },
  modalMessage: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 20,
    fontFamily: 'Poppins-Regular',
    color: colors.black,
  },
  modalButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  modalButton: {
    flex: 1,
    paddingVertical: 10,
    borderRadius: 5,
    marginHorizontal: 5,
    alignItems: 'center',
  },
  cancelButton: {
    backgroundColor: colors.lightGray2,
  },
  proceedButton: {
    backgroundColor: colors.green, // Use your preferred color
  },
  cancelButtonText: {
    color: colors.black,
    fontSize: 16,
    fontFamily: 'Poppins-Regular',
  },
  proceedButtonText: {
    color: colors.white,
    fontSize: 16,
    fontFamily: 'Poppins-Regular',
  },
});

export default BuyCredit;
