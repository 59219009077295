import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import colors from '../../strings/colors';
import { useDispatch, useSelector } from 'react-redux';
import { soundActions } from '../../redux/soundSlice';
import { settingsActions } from '../../redux/settingsSlice';

const SoundToggle = () => {

  const settings = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  const handlePress = () => {
    dispatch(settingsActions.toggleSound());
  };


  return (
    <View>
      <TouchableOpacity style={{marginRight:10}} onPress={handlePress}>
        {settings.sound_on ? (
          <Ionicons name="volume-high-outline" size={28} color={colors.white} />
        ) : (
          <Ionicons name="volume-mute-outline" size={28} color={colors.red} />
        )}
      </TouchableOpacity>
    </View>
  );
};

export default SoundToggle;
