// FlatButton.js
import React, { useEffect, useRef } from 'react';
import { TouchableOpacity, Text, StyleSheet, Animated } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient'; // Use 'react-native-linear-gradient' if not using Expo
import colors from '../../strings/colors';

const FlatButton = ({ title, onPress, color, disabled = false }) => {
  
  // Define gradient colors based on the button color for a more vibrant look
  const getGradientColors = () => {
    switch (color) {
      case 'red':
        return ['#FF0000', '#FF5C5C'];
      case 'green':
        return ['#6bffb8', '#00CE34'];
      case 'yellow':
        return ['#FFC800', '#f2c94c'];
      default:
        return [colors.gray, colors.dark_gray];
    }
  };

  // Define glow colors based on the button color
  const getGlowColor = () => {
    switch (color) {
      case 'red':
        return '#ff6b6b'; // Bright red glow
      case 'green':
        return '#6bffb8'; // Bright green glow
      case 'yellow':
        return '#fff176'; // Bright yellow glow
      default:
        return '#ffffff'; // Default white glow
    }
  };

  // Animated value for the glow opacity (Optional for Pulse Animation)
  const glowAnim = useRef(new Animated.Value(0.8)).current;

  useEffect(() => {
    // Pulse animation loop (Optional)
    Animated.loop(
      Animated.sequence([
        Animated.timing(glowAnim, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: false,
        }),
        Animated.timing(glowAnim, {
          toValue: 0.8,
          duration: 1000,
          useNativeDriver: false,
        }),
      ])
    ).start();
  }, [glowAnim]);

  return (
    <Animated.View style={[styles.glowContainer, { shadowColor: getGlowColor(), shadowOpacity: glowAnim }]}>
      <TouchableOpacity
        style={[
          styles.button,
          disabled && styles.disabledButton
        ]}
        onPress={onPress}
        disabled={disabled}
        activeOpacity={0.7} // Provides feedback on press
        accessibilityLabel={title}
        accessibilityRole="button"
        accessible={true}
      >
        <LinearGradient
          colors={getGradientColors()}
          style={styles.gradient}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 1 }}
        >
          <Text style={[styles.title, disabled && styles.disabledText]}>
            {title}
          </Text>
        </LinearGradient>
      </TouchableOpacity>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  glowContainer: {
    // This container adds the outer glow
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 10,
    elevation: 10, // For Android
    borderRadius: 8, // Match the button's borderRadius
    margin: 5, // Space around the glow
  },
  button: {
    width: 90,
    height: 40,
    borderRadius: 6,
    overflow: 'hidden', // Ensures the gradient doesn't spill outside the button
    // Inner shadow for depth
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.3,
    shadowRadius: 8,
    elevation: 5, // For Android
  },
  gradient: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // Optional: Add border to enhance the neon look
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.3)',
  },
  title: {
    fontFamily: 'Gloucester',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    color: colors.black, // Changed to white for better contrast on colorful backgrounds
    textShadowColor: 'rgba(0, 0, 0, 0.25)',
    textShadowOffset: { width: 0, height: 1 },
    textShadowRadius: 1,
  },
  disabledButton: {
    opacity: 0.6,
  },
  disabledText: {
    color: 'rgba(255, 255, 255, 0.7)',
  },
});

export default FlatButton;
