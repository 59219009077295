export default {
    primary: '#fc5c65',
    secondary: '#4ecdc4',
    black: '#000',
    red: '#c62d1f',
    orange:'#ff7f00',
    blue: '#1e90ff',
    white: '#fff',
    green:'#005300',
    dark_green: '#043010',
    light_green: '#00b300',
    gray: '#2a2e2f',
    light_gray: '#9b9b9b',
    dark_gold: '#866501',
    light_gold: '#d48300',
    gold: '#b0953a',
    dark_blue: '#235af1',
    deep_blue: '#081740',
    light_blue:'#143183',
    yellow: '#f2c94c',
    light_gray2: "#ccc",
    noteLightBlue: '#45C0D3',   // Light Blue for 10 N$
    noteRed: '#D06740',         // Red for 20 N$
    noteGreen: '#4BB091',       // Green for 30 N$
    noteOrange: '#FFA500',      // Orange for 50 N$
    noteDarkRed: '#EE3554',    // Dark Blue for 100 N$
    notePurple: '#AB8CFF',
}