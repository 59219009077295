import React from 'react'
import { Image, StyleSheet, Text } from 'react-native'
import colors from '../../strings/colors'
const siskusIcon = require('../../../assets/images/siskusicon.svg');
const siskusXmas = require('../../../assets/images/x-mas-icon.png');

function SiskusIcon({title,description}) {

    const currentMonth = new Date().getMonth() + 1;

    const icon = currentMonth > 10 ? siskusXmas : siskusIcon;

  return (
    <>
        <Image
            style={[styles.icon,{
                width: currentMonth>10? 100 : 80,
                height: currentMonth>10? 100 : 80,
                marginBottom: 10,
            
            }]}
            source={icon}  // Adjust the path to your image
        />
         <Text style={styles.title}>{title}</Text>
        <Text style={styles.des}>{description}</Text>
       
    </>
    
  )
}

const styles = StyleSheet.create({
    icon: {
      width: 80,
      height: 80,
      alignSelf: 'center',
      marginBottom: 10,
    },
    title: {
      fontFamily: 'Poppins-Bold',
      fontSize: 24,
      color: colors.gray,
      textAlign: 'center',
      marginTop: 10,
      marginBottom: 10,
    },
    des:{
      fontFamily: 'Poppins-Bold',
      fontSize: 16,
      color: colors.light_gray,
      textAlign: 'center',
      marginBottom: 20,
    },
   
  });

export default SiskusIcon